import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import YoutubeVideo9008WiFi from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/YoutubeVideoCard9008WiFi';
import YoutubeVideo8015WiFi from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/YoutubeVideoCard8015WiFi';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "How to set up a Wireless Connection",
  "path": "/Quick_Installation/Set_Up_A_Wireless_Connection/",
  "dateChanged": "2017-11-24",
  "author": "Mike Polinowski",
  "excerpt": "How to set up a Wireless Connection",
  "image": "./QI_SearchThumb_WLAN.png",
  "social": "/images/Search/QI_SearchThumb_WLAN.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Wireless_Connection_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='How to set up a Wireless Connection' dateChanged='2017-11-24' author='Mike Polinowski' tag='INSTAR IP Camera' description='How to set up a Wireless Connection' image='/images/Search/QI_SearchThumb_WLAN.png' twitter='/images/Search/QI_SearchThumb_WLAN.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Herstellen_der_WLAN_Verbindung/' locationFR='/fr/Quick_Installation/Set_Up_A_Wireless_Connection/' crumbLabel="Wireless" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h2 {...{
      "id": "set-up-a-wifi-connection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#set-up-a-wifi-connection",
        "aria-label": "set up a wifi connection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set up a WiFi connection`}</h2>
    <p>{`Please be aware it is best to install your camera via LAN cable first before it can be used in your wireless network - see `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/First_Steps/"
      }}>{`First Steps`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/After_Unpacking/"
      }}>{`After Unpacking`}</a>{`. Our 720p and 1080p cameras allow you to use your router´s WPS function instead, to connect them directly to your WiFi network. Please check our User manuals and `}<a parentName="p" {...{
        "href": "/en/Products/Usermanuals/"
      }}>{`Quick Installation Guides`}</a>{` for details.`}</p>
    <p>{`Please use the included Ethernet cable to connect the camera with your router. After the camera is connected, please open the web interface of your IP camera in your default web browser. Connect the WiFi antenna to the `}<a parentName="p" {...{
        "href": "/en/Products/SMA_RP-SMA_Antenna_Connector/"
      }}>{`SMA/RP-SMA`}</a>{` on camera (only for models with removable antenna), align it vertically to your router and place the camera close to your WiFi router for the rest of the installation process.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#set-up-a-wifi-connection"
        }}>{`Set up a WiFi connection`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#plugplay-wps"
        }}>{`Plug&Play (WPS)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#wifi-access-point"
        }}>{`WiFi Access Point`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#manual-installation---1080p-cameras"
        }}>{`Manual Installation - 1080p Cameras`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#manual-installation---720p-cameras"
        }}>{`Manual Installation - 720p Cameras`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#manual-installation---vga-cameras"
        }}>{`Manual Installation - VGA Cameras`}</a></li>
    </ul>
    {/* /TOC */}
    <YoutubeVideo9008WiFi mdxType="YoutubeVideo9008WiFi" />
    <EuiSpacer mdxType="EuiSpacer" />
    <YoutubeVideo8015WiFi mdxType="YoutubeVideo8015WiFi" />
    <h2 {...{
      "id": "plugplay-wps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#plugplay-wps",
        "aria-label": "plugplay wps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Plug&Play (WPS)`}</h2>
    <p>{`All `}<strong parentName="p">{`1080p and 720p camera models`}</strong>{` support the Plug & Play WPS method to be added to your Wi-Fi network at the touch of a button.`}</p>
    <p>{`We have collected `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Set_Up_A_Wireless_Connection/WPS_Plug_and_Play/"
      }}>{`video tutorials in a separate article`}</a>{` which explain the WPS process with common router models. In general, press the reset button on your camera (`}<strong parentName="p">{`for 3s`}</strong>{`) and the WPS button on the router must be pressed at the same time. The WLAN integration takes place automatically afterwards. `}<strong parentName="p">{`In case of the camera models IN-9020 Full HD and IN-9010 Full HD`}</strong>{` please `}<a parentName="p" {...{
        "href": "/en/Outdoor_Cameras/IN-9020_HD/Camera_Reset/#wlan-integration-after-the-reset"
      }}>{`use the internal SD card to activate the WPS mode`}</a></p>
    <p>{`Afterwards, you can access the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`web interface of the camera`}</a>{` with help of the `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Alternative_IP_Scanner/"
      }}>{`INSTAR Camera Tool`}</a>{` or access it directly via the IP address assigned by the router.`}</p>
    <p>{`After the initial setup via the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`web interface of the camera`}</a>{` you can use our  `}<strong parentName="p">{`InstarVision`}</strong>{` software platform for `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/"
      }}>{`Windows`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/"
      }}>{`Windows Metro (UWP)`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/"
      }}>{`Windows Phone`}</a>{`, `}<a href="https://www.instar.de/software/instarvision-for-windows-and-macos.html" target="blank">{`macOS`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/"
      }}>{`Android`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/"
      }}>{`iPhone & iPad`}</a>{` to control your camera.`}</p>
    <br />
    <h2 {...{
      "id": "wifi-access-point",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#wifi-access-point",
        "aria-label": "wifi access point permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`WiFi Access Point`}</h2>
    <p><strong parentName="p">{`The access point is only active during the initial installation or after a factory reset!`}</strong></p>
    <p>{`In the case of the `}<strong parentName="p">{`1080p models`}</strong>{` there is another option to connect your camera with your local WiFi. The camera can act as a separate WiFi access point (if the `}<strong parentName="p">{`Ethernet cable is not plugged in`}</strong>{`). You can connect to the camera's Wi-Fi network with your mobile phone or laptop and then access your camera via the `}<strong parentName="p">{`IP 192.168.9.1`}</strong>{` in the browser. You will be directed to the wireless settings of the camera, there you can select your wireless network and enter your WiFi key.`}</p>
    <p>{`The camera will now check the data and will connect to your Wi-Fi if valid. Afterwards, you can access the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`web interface of the camera`}</a>{` with help of the `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Alternative_IP_Scanner/"
      }}>{`INSTAR Camera Tool`}</a>{` or access it directly via the IP address assigned by the router.`}</p>
    <p>{`After the initial setup via the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Overview/"
      }}>{`web interface of the camera`}</a>{` you can use our  `}<strong parentName="p">{`InstarVision`}</strong>{` software platform for `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/"
      }}>{`Windows`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Metro/"
      }}>{`Windows Metro (UWP)`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Windows/InstarVision/Windows_Phone/"
      }}>{`Windows Phone`}</a>{`, `}<a href="https://www.instar.de/software/instarvision-for-windows-and-macos.html" target="blank">{`macOS`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Android/InstarVision/"
      }}>{`Android`}</a>{` and `}<a parentName="p" {...{
        "href": "/en/Software/iOS/InstarVision/"
      }}>{`iPhone & iPad`}</a>{` to control your camera.`}</p>
    <br />
    <h2 {...{
      "id": "manual-installation---1080p-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#manual-installation---1080p-cameras",
        "aria-label": "manual installation   1080p cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manual Installation - 1080p Cameras`}</h2>
    <p><strong parentName="p">{`ATTENTION`}</strong>{`:`}</p>
    <ol>
      <li parentName="ol">{`Please make sure the password does not include special characters such as &="+\`\\`}</li>
      <li parentName="ol">{`Please make sure that the MAC Filter function is not activated in your router. The cameras LAN module has a different MAC address then the WiFi board. If you are using the WiFi access control of your router, please deactivate it, let the camera connect and add the new MAC address to the list of trusted devices before reactivating the filter.`}</li>
      <li parentName="ol">{`If you have trouble connecting to the network, try setting the router´s WiFi channel to channel 6 or a channel which is not being used by routers nearby.`}</li>
      <li parentName="ol">{`If you have the camera set to use the DHCP service be aware that it will receive a new IP address because the MAC address for the WiFi board is different from the LAN MAC address. Simply open the INSTAR camera tool to reconnect to the camera. We recommend using a static IP address if you are having difficulties connecting to the wireless network. You can set a static IP in the web user interface in Network/IP configuration.`}</li>
      <li parentName="ol">{`If you are using a WEP 128 Bit encryption and you encounter any problems, we recommend you to switch your encryption to WPA (AES) or WPA2 (AES or CCMP). Also avoid mixed modes like WPA+WPA2 (TKIP).`}</li>
    </ol>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please open the menu `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/WiFi/"
      }}>{`Network / Wifi`}</a>{` as shown below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "745px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/00cb31f6e1a3e3951c373281ea41d2c3/7e509/WiFi_Setup_1080p.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB1klEQVQoz21QXW/TQBD0j0TlDagAif8UatQ2jpJURAoFCfHGSykxgcROigJNItqS2vdhu3HOzp33bpEdWlVqRqPV6FY7s3vWC/vzc+fb04Ovz+q93br75KC3Y5/u2KePq/qQj/a+7NbdV83+S8e13pxM2yPW9knbp22ftjziDEoeDogzJI2K9eplU50hOfJpZ8yORsxKkrjps5p73RiELY82PWL3g6ZHL5P14feg1lvsuYvmMLxlYLuLWu/v696CpMqiLBpdUu+Cja/42RX3L8iPeeD/CdM1zHg249k8Xs95dk5X8yibcTFlFbnIlbaiiN9wKkWqciGzVbZMMkZuOAUAhAKNRkQ0WsscQaEBRI0IlUArjuOyawzegyoKAJBKCSEANIBepqnIMqmUKgqp1FrKLcMbIaUEgKICIgJA6aK1rpyKopCb4WhrslIAoCroCkKIPF/nFcp4pe6SjYbCaDBG309W/9cGCSaMUhqnJE4pT5ZpJnJljLGSJHmYrHXpUq0Jt62yaoMSsSh/rISVsGsct4zn4HAfV+Hm7juXjRyHq+6YHk/4ySTgjRZttMm+o0JiJSzA3x/Mzw6edVCwrcO/qPg44Z/O4/6UxN33tPOOvu0qxv8BOEqGAUJsE/IAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/00cb31f6e1a3e3951c373281ea41d2c3/e4706/WiFi_Setup_1080p.avif 230w", "/en/static/00cb31f6e1a3e3951c373281ea41d2c3/d1af7/WiFi_Setup_1080p.avif 460w", "/en/static/00cb31f6e1a3e3951c373281ea41d2c3/57346/WiFi_Setup_1080p.avif 745w"],
              "sizes": "(max-width: 745px) 100vw, 745px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/00cb31f6e1a3e3951c373281ea41d2c3/a0b58/WiFi_Setup_1080p.webp 230w", "/en/static/00cb31f6e1a3e3951c373281ea41d2c3/bc10c/WiFi_Setup_1080p.webp 460w", "/en/static/00cb31f6e1a3e3951c373281ea41d2c3/06157/WiFi_Setup_1080p.webp 745w"],
              "sizes": "(max-width: 745px) 100vw, 745px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/00cb31f6e1a3e3951c373281ea41d2c3/81c8e/WiFi_Setup_1080p.png 230w", "/en/static/00cb31f6e1a3e3951c373281ea41d2c3/08a84/WiFi_Setup_1080p.png 460w", "/en/static/00cb31f6e1a3e3951c373281ea41d2c3/7e509/WiFi_Setup_1080p.png 745w"],
              "sizes": "(max-width: 745px) 100vw, 745px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/00cb31f6e1a3e3951c373281ea41d2c3/7e509/WiFi_Setup_1080p.png",
              "alt": "INSTAR Full HD Camera Wireless Installation",
              "title": "INSTAR Full HD Camera Wireless Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click to search for networks, select your WLAN, add your password and click test. Apply your settings after a successful test, unplug the Ethernet cable from your camera and restart it. The camera will automatically connect to your wireless network and might receive a new IP address from your router by DHCP (if activated).`}</p>
    <br />
    <h2 {...{
      "id": "manual-installation---720p-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#manual-installation---720p-cameras",
        "aria-label": "manual installation   720p cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manual Installation - 720p Cameras`}</h2>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please open the menu `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Network/WiFi/"
      }}>{`Network / WiFi`}</a>{` as shown below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6f6400b59a44f6295d7a1ed75576ce0f/29d31/WLAN_Einrichtung_HD_01_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAFAg6Vio//EABwQAAEDBQAAAAAAAAAAAAAAAAABAhIDBCIxMv/aAAgBAQABBQKriMVZSQue2bP/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAACAwEAAAAAAAAAAAAAAAAQIQACMXH/2gAIAQEABj8CSminD//EAB8QAAIBAgcAAAAAAAAAAAAAAAABETGxECFRYYGhwf/aAAgBAQABPyHkpo65kfLRo2bJ3tz0th//2gAMAwEAAgADAAAAEHMv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQIBAT8QR//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQWFxkf/aAAgBAQABPxC5FCKUtU1qalauxeXxPF+zJGDaHpEpVV7QrfLP/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f6400b59a44f6295d7a1ed75576ce0f/e4706/WLAN_Einrichtung_HD_01_EN.avif 230w", "/en/static/6f6400b59a44f6295d7a1ed75576ce0f/d1af7/WLAN_Einrichtung_HD_01_EN.avif 460w", "/en/static/6f6400b59a44f6295d7a1ed75576ce0f/70e80/WLAN_Einrichtung_HD_01_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6f6400b59a44f6295d7a1ed75576ce0f/a0b58/WLAN_Einrichtung_HD_01_EN.webp 230w", "/en/static/6f6400b59a44f6295d7a1ed75576ce0f/bc10c/WLAN_Einrichtung_HD_01_EN.webp 460w", "/en/static/6f6400b59a44f6295d7a1ed75576ce0f/426ac/WLAN_Einrichtung_HD_01_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6f6400b59a44f6295d7a1ed75576ce0f/e83b4/WLAN_Einrichtung_HD_01_EN.jpg 230w", "/en/static/6f6400b59a44f6295d7a1ed75576ce0f/e41a8/WLAN_Einrichtung_HD_01_EN.jpg 460w", "/en/static/6f6400b59a44f6295d7a1ed75576ce0f/29d31/WLAN_Einrichtung_HD_01_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6f6400b59a44f6295d7a1ed75576ce0f/29d31/WLAN_Einrichtung_HD_01_EN.jpg",
              "alt": "INSTAR HD Camera Wireless Installation",
              "title": "INSTAR HD Camera Wireless Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the WiFi menu in the web user interface.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Click on the Search button to activate the WiFi module and let the camera search for active 2.4GHz networks in its environment.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "478px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/07bf39302dee992b87f2cc1620b1965a/0c439/WLAN_Einrichtung_HD_02_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "136.52173913043478%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAECAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB6lpvZm1S5zaQD//EABsQAAMAAgMAAAAAAAAAAAAAAAACEQEQEiAx/9oACAEBAAEFArCnFW0shTDQ96f/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAbEAABBAMAAAAAAAAAAAAAAAABABBBkREwMf/aAAgBAQAGPwKLUWskAtzR/8QAHRAAAwABBQEAAAAAAAAAAAAAAAERMRAhQZGhgf/aAAgBAQABPyG2iX2MSz3djSi28E4HQ5wPYlCTHpcpBYRNP//aAAwDAQACAAMAAAAQ184M/8QAFREBAQAAAAAAAAAAAAAAAAAAICH/2gAIAQMBAT8Qo//EABURAQEAAAAAAAAAAAAAAAAAACAh/9oACAECAQE/EIP/xAAfEAEAAgICAgMAAAAAAAAAAAABESEAMUFRcZEQYdH/2gAIAQEAAT8QZGiuB14zRz5I/mNAipgp5xkhsLhdZsjDrADUD6IyJAINyXWCxe+uG97JwDMDRkOj18f/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/07bf39302dee992b87f2cc1620b1965a/e4706/WLAN_Einrichtung_HD_02_EN.avif 230w", "/en/static/07bf39302dee992b87f2cc1620b1965a/d1af7/WLAN_Einrichtung_HD_02_EN.avif 460w", "/en/static/07bf39302dee992b87f2cc1620b1965a/7a12e/WLAN_Einrichtung_HD_02_EN.avif 478w"],
              "sizes": "(max-width: 478px) 100vw, 478px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/07bf39302dee992b87f2cc1620b1965a/a0b58/WLAN_Einrichtung_HD_02_EN.webp 230w", "/en/static/07bf39302dee992b87f2cc1620b1965a/bc10c/WLAN_Einrichtung_HD_02_EN.webp 460w", "/en/static/07bf39302dee992b87f2cc1620b1965a/2b263/WLAN_Einrichtung_HD_02_EN.webp 478w"],
              "sizes": "(max-width: 478px) 100vw, 478px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/07bf39302dee992b87f2cc1620b1965a/e83b4/WLAN_Einrichtung_HD_02_EN.jpg 230w", "/en/static/07bf39302dee992b87f2cc1620b1965a/e41a8/WLAN_Einrichtung_HD_02_EN.jpg 460w", "/en/static/07bf39302dee992b87f2cc1620b1965a/0c439/WLAN_Einrichtung_HD_02_EN.jpg 478w"],
              "sizes": "(max-width: 478px) 100vw, 478px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/07bf39302dee992b87f2cc1620b1965a/0c439/WLAN_Einrichtung_HD_02_EN.jpg",
              "alt": "INSTAR HD Camera Wireless Installation",
              "title": "INSTAR HD Camera Wireless Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Use the camera´s search function to discover available 2.4GHz WiFi networks. Choose your WiFi network from the list.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: Now simply add your WiFi password and confirm your input. Pressing the Submit button will save the information and Test the connection. Once the was successful, unplug the Ethernet cable and restart your camera. The camera will automatically connect to your wireless network and might receive a new IP address from your router by DHCP (if activated).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "478px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b9405026e369ec911093adea735f5576/0c439/WLAN_Einrichtung_HD_04_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "136.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDAQX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB9W1ENBi17LC1n//EABoQAAICAwAAAAAAAAAAAAAAAAABICECERL/2gAIAQEAAQUCss4xYmbgof/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ASn/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAaEAACAgMAAAAAAAAAAAAAAAAAASAxAiLh/9oACAEBAAY/AqRSNsVHsf/EAB8QAQACAQMFAAAAAAAAAAAAAAEAEVEhMXEQQWGRwf/aAAgBAQABPyGw0MeWbPtHUoeIGSUySyVT39y+YGkNiUY6f//aAAwDAQACAAMAAAAQIAa9/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEREP/aAAgBAwEBPxCPKKz/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxAh/8QAIhABAAMAAAQHAAAAAAAAAAAAAQARIRBBYZExUXGB0fDx/9oACAEBAAE/EA7ENo5ekLB08qfEZo3LQ5KAbAXpmT9SCeCPvKXLreU7XKbdPrrL0t689gG4HCdB24f/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9405026e369ec911093adea735f5576/e4706/WLAN_Einrichtung_HD_04_EN.avif 230w", "/en/static/b9405026e369ec911093adea735f5576/d1af7/WLAN_Einrichtung_HD_04_EN.avif 460w", "/en/static/b9405026e369ec911093adea735f5576/7a12e/WLAN_Einrichtung_HD_04_EN.avif 478w"],
              "sizes": "(max-width: 478px) 100vw, 478px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b9405026e369ec911093adea735f5576/a0b58/WLAN_Einrichtung_HD_04_EN.webp 230w", "/en/static/b9405026e369ec911093adea735f5576/bc10c/WLAN_Einrichtung_HD_04_EN.webp 460w", "/en/static/b9405026e369ec911093adea735f5576/2b263/WLAN_Einrichtung_HD_04_EN.webp 478w"],
              "sizes": "(max-width: 478px) 100vw, 478px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b9405026e369ec911093adea735f5576/e83b4/WLAN_Einrichtung_HD_04_EN.jpg 230w", "/en/static/b9405026e369ec911093adea735f5576/e41a8/WLAN_Einrichtung_HD_04_EN.jpg 460w", "/en/static/b9405026e369ec911093adea735f5576/0c439/WLAN_Einrichtung_HD_04_EN.jpg 478w"],
              "sizes": "(max-width: 478px) 100vw, 478px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b9405026e369ec911093adea735f5576/0c439/WLAN_Einrichtung_HD_04_EN.jpg",
              "alt": "INSTAR HD Camera Wireless Installation",
              "title": "INSTAR HD Camera Wireless Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please add your WiFi password and click Submit to save the information.`}</p>
    <br />
    <h2 {...{
      "id": "manual-installation---vga-cameras",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#manual-installation---vga-cameras",
        "aria-label": "manual installation   vga cameras permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manual Installation - VGA Cameras`}</h2>
    <p><strong parentName="p">{`ATTENTION`}</strong>{`:`}</p>
    <ul>
      <li parentName="ul">{`Please make sure that the MAC Filter function is not activated in your router. The cameras LAN module has a different MAC address then the WiFi board. If you are using the WiFi access control of your router, please deactivate it, let the camera connect and add the new MAC address to the list of trusted devices before reactivating the filter.`}</li>
      <li parentName="ul">{`If you have trouble connecting to the network, try setting the router´s WiFi channel to channel 6 or a channel which is not being used by routers nearby.`}</li>
      <li parentName="ul">{`If you have the camera set to use the DHCP service be aware that it will receive a new IP address because the MAC address for the WiFi board is different from the LAN MAC address. Simply open the INSTAR camera tool to reconnect to the camera. We recommend using a static IP address if you are having difficulties connecting to the wireless network. You can set a static IP in the web user interface in Network/IP configuration.`}</li>
      <li parentName="ul">{`If you are using a WEP 128 Bit encryption and you encounter any problems, we recommend you to switch your encryption to WPA (AES) or WPA2 (AES or CCMP). Also avoid mixed modes like WPA+WPA2 (TKIP).`}</li>
    </ul>
    <p><strong parentName="p">{`Step 1`}</strong>{`: Please open the menu Network/WiFi as shown below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/63f6378167d162d803e4d0d7407fbb83/29d31/WLAN_Einrichtung_MJPEG_01_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHpXWgpof/EABYQAQEBAAAAAAAAAAAAAAAAAAIQEf/aAAgBAQABBQInKb//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAXEAADAQAAAAAAAAAAAAAAAAAAATEg/9oACAEBAAY/AoiEx//EABkQAAMBAQEAAAAAAAAAAAAAAAABIREQQf/aAAgBAQABPyGzLOiz0NITvf/aAAwDAQACAAMAAAAQoM//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAxYSH/2gAIAQEAAT8QIK5lEb6zHi75zV/AGAAENTKb/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63f6378167d162d803e4d0d7407fbb83/e4706/WLAN_Einrichtung_MJPEG_01_EN.avif 230w", "/en/static/63f6378167d162d803e4d0d7407fbb83/d1af7/WLAN_Einrichtung_MJPEG_01_EN.avif 460w", "/en/static/63f6378167d162d803e4d0d7407fbb83/70e80/WLAN_Einrichtung_MJPEG_01_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/63f6378167d162d803e4d0d7407fbb83/a0b58/WLAN_Einrichtung_MJPEG_01_EN.webp 230w", "/en/static/63f6378167d162d803e4d0d7407fbb83/bc10c/WLAN_Einrichtung_MJPEG_01_EN.webp 460w", "/en/static/63f6378167d162d803e4d0d7407fbb83/426ac/WLAN_Einrichtung_MJPEG_01_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/63f6378167d162d803e4d0d7407fbb83/e83b4/WLAN_Einrichtung_MJPEG_01_EN.jpg 230w", "/en/static/63f6378167d162d803e4d0d7407fbb83/e41a8/WLAN_Einrichtung_MJPEG_01_EN.jpg 460w", "/en/static/63f6378167d162d803e4d0d7407fbb83/29d31/WLAN_Einrichtung_MJPEG_01_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/63f6378167d162d803e4d0d7407fbb83/29d31/WLAN_Einrichtung_MJPEG_01_EN.jpg",
              "alt": "INSTAR VGA Camera Wireless Installation",
              "title": "INSTAR VGA Camera Wireless Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Open the WiFi menu in the web user interface.`}</p>
    <p><strong parentName="p">{`Step 2`}</strong>{`: Click on the Scan button twice to activate the WiFi module and let the camera search for active 2.4GHz networks in its environment.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e176f86a831d4025316ba55cbe539571/29d31/WLAN_Einrichtung_MJPEG_02_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHpWyggwf/EABkQAAMAAwAAAAAAAAAAAAAAAAABAhARIf/aAAgBAQABBQJSpFo4Tn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAAAhESD/2gAIAQEABj8CI6//xAAaEAACAgMAAAAAAAAAAAAAAAAAARExECFR/9oACAEBAAE/IaozbcEloa4FvP8A/9oADAMBAAIAAwAAABCjz//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABoQAQACAwEAAAAAAAAAAAAAAAEAIRExYVH/2gAIAQEAAT8QsUkobeyxs5MigEAAFTB4RCf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e176f86a831d4025316ba55cbe539571/e4706/WLAN_Einrichtung_MJPEG_02_EN.avif 230w", "/en/static/e176f86a831d4025316ba55cbe539571/d1af7/WLAN_Einrichtung_MJPEG_02_EN.avif 460w", "/en/static/e176f86a831d4025316ba55cbe539571/70e80/WLAN_Einrichtung_MJPEG_02_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e176f86a831d4025316ba55cbe539571/a0b58/WLAN_Einrichtung_MJPEG_02_EN.webp 230w", "/en/static/e176f86a831d4025316ba55cbe539571/bc10c/WLAN_Einrichtung_MJPEG_02_EN.webp 460w", "/en/static/e176f86a831d4025316ba55cbe539571/426ac/WLAN_Einrichtung_MJPEG_02_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e176f86a831d4025316ba55cbe539571/e83b4/WLAN_Einrichtung_MJPEG_02_EN.jpg 230w", "/en/static/e176f86a831d4025316ba55cbe539571/e41a8/WLAN_Einrichtung_MJPEG_02_EN.jpg 460w", "/en/static/e176f86a831d4025316ba55cbe539571/29d31/WLAN_Einrichtung_MJPEG_02_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e176f86a831d4025316ba55cbe539571/29d31/WLAN_Einrichtung_MJPEG_02_EN.jpg",
              "alt": "INSTAR VGA Camera Wireless Installation",
              "title": "INSTAR VGA Camera Wireless Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Use the camera´s search function to discover available 2.4GHz WiFi networks.`}</p>
    <p><strong parentName="p">{`Step 3`}</strong>{`: Choose your WiFi network from the list to automatically copy the network´s details into the cameras WiFi settings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aceced71bb00ec4dcc5199b17d27bf58/29d31/WLAN_Einrichtung_MJPEG_03_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIDBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAelqslQ//8QAFhABAQEAAAAAAAAAAAAAAAAAAQIQ/9oACAEBAAEFAgJ2d//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABYQAQEBAAAAAAAAAAAAAAAAACERIP/aAAgBAQAGPwIjr//EABoQAAICAwAAAAAAAAAAAAAAAAABESEQMUH/2gAIAQEAAT8h0xuqFDIQiKx//9oADAMBAAIAAwAAABDTD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABwQAQACAwADAAAAAAAAAAAAAAEAESExYVFx0f/aAAgBAQABPxDMNNUDL2Dco+CvkToq+sJZRjU9I1ep/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aceced71bb00ec4dcc5199b17d27bf58/e4706/WLAN_Einrichtung_MJPEG_03_EN.avif 230w", "/en/static/aceced71bb00ec4dcc5199b17d27bf58/d1af7/WLAN_Einrichtung_MJPEG_03_EN.avif 460w", "/en/static/aceced71bb00ec4dcc5199b17d27bf58/70e80/WLAN_Einrichtung_MJPEG_03_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aceced71bb00ec4dcc5199b17d27bf58/a0b58/WLAN_Einrichtung_MJPEG_03_EN.webp 230w", "/en/static/aceced71bb00ec4dcc5199b17d27bf58/bc10c/WLAN_Einrichtung_MJPEG_03_EN.webp 460w", "/en/static/aceced71bb00ec4dcc5199b17d27bf58/426ac/WLAN_Einrichtung_MJPEG_03_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aceced71bb00ec4dcc5199b17d27bf58/e83b4/WLAN_Einrichtung_MJPEG_03_EN.jpg 230w", "/en/static/aceced71bb00ec4dcc5199b17d27bf58/e41a8/WLAN_Einrichtung_MJPEG_03_EN.jpg 460w", "/en/static/aceced71bb00ec4dcc5199b17d27bf58/29d31/WLAN_Einrichtung_MJPEG_03_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aceced71bb00ec4dcc5199b17d27bf58/29d31/WLAN_Einrichtung_MJPEG_03_EN.jpg",
              "alt": "INSTAR VGA Camera Wireless Installation",
              "title": "INSTAR VGA Camera Wireless Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Select your network from the list.`}</p>
    <p><strong parentName="p">{`Step 4`}</strong>{`: Now simply add your WiFi password (shared key) and press the Submit button to save the information. To connect the camera to your wireless network and unplug the Ethernet cable. The camera will automatically connect to your wireless network and receive a new IP address from your router by DHCP (if activated).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "700px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9de2caf5e4301336ef0af72885990e2/29d31/WLAN_Einrichtung_MJPEG_04_EN.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72.17391304347827%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHSuhxRg//EABgQAAMBAQAAAAAAAAAAAAAAAAECEAAh/9oACAEBAAEFAgAu5Fv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAWEAEBAQAAAAAAAAAAAAAAAAAhESD/2gAIAQEABj8CI6//xAAbEAACAgMBAAAAAAAAAAAAAAAAASExEBFRQf/aAAgBAQABPyGmN6gW1jS4IiOY/9oADAMBAAIAAwAAABCTD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EABsQAQADAQADAAAAAAAAAAAAAAEAESExUWFx/9oACAEBAAE/ENhpqga+4+DfBLOCEsozkot0v5Gr5P/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9de2caf5e4301336ef0af72885990e2/e4706/WLAN_Einrichtung_MJPEG_04_EN.avif 230w", "/en/static/a9de2caf5e4301336ef0af72885990e2/d1af7/WLAN_Einrichtung_MJPEG_04_EN.avif 460w", "/en/static/a9de2caf5e4301336ef0af72885990e2/70e80/WLAN_Einrichtung_MJPEG_04_EN.avif 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9de2caf5e4301336ef0af72885990e2/a0b58/WLAN_Einrichtung_MJPEG_04_EN.webp 230w", "/en/static/a9de2caf5e4301336ef0af72885990e2/bc10c/WLAN_Einrichtung_MJPEG_04_EN.webp 460w", "/en/static/a9de2caf5e4301336ef0af72885990e2/426ac/WLAN_Einrichtung_MJPEG_04_EN.webp 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9de2caf5e4301336ef0af72885990e2/e83b4/WLAN_Einrichtung_MJPEG_04_EN.jpg 230w", "/en/static/a9de2caf5e4301336ef0af72885990e2/e41a8/WLAN_Einrichtung_MJPEG_04_EN.jpg 460w", "/en/static/a9de2caf5e4301336ef0af72885990e2/29d31/WLAN_Einrichtung_MJPEG_04_EN.jpg 700w"],
              "sizes": "(max-width: 700px) 100vw, 700px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9de2caf5e4301336ef0af72885990e2/29d31/WLAN_Einrichtung_MJPEG_04_EN.jpg",
              "alt": "INSTAR VGA Camera Wireless Installation",
              "title": "INSTAR VGA Camera Wireless Installation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Please add your WiFi password and click Submit to save the information.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      